import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../axios";
import { useAuth } from "../../../contexts/AuthContext";
import { Box, Checkbox, Stack, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { CookieContext } from "../../../contexts/CookieContext"; // Import du CookieContext

export default function Register() {
  const { cookiesAccepted, acceptCookies } = useContext(CookieContext); // Utiliser le CookieContext
  const { user, setUser } = useAuth();
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsButtonEnabled(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, cpassword } = e.target.elements;
    const body = {
      name: name.value,
      email: email.value,
      password: password.value,
      password_confirmation: cpassword.value,
    };
    try {
      const resp = await axios.post("/register", body);
      if (resp.status === 200) {
        setUser({ user: resp.data.user, remember: rememberMe });
        console.log("user", user, resp.data.user);
        navigate("/profile");
      }
    } catch (error) {
      if (error.response.status === 422) {
        console.log(error.response.data.errors);
        if (error.response.data.errors.name) {
          setNameError(error.response.data.errors.name[0]);
        } else {
          setNameError("");
        }
        if (error.response.data.errors.email) {
          setEmailError(error.response.data.errors.email[0]);
        } else {
          setEmailError("");
        }
        if (error.response.data.errors.password) {
          setPasswordError(error.response.data.errors.password[0]);
        } else {
          setPasswordError("");
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
      }}
    >
      <Stack direction={"column"} spacing={2} sx={{ pt: 4, pb: 4, width: { xs: "96vw", md: "40vw" } }}>
        <Helmet>
          <title>Créer un compte EXCHANGE.COM</title>
          <meta
            name="description"
            content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
          />
        </Helmet>
        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          <img src="images/logo1.png" alt="" style={{ borderRadius: "50%", width: 80 }} />
        </Stack>
        <Box className="bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700" sx={{ pt: 4, pb: 2 }}>
          <div className="p-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Créer votre compte
            </h1>

            {/* Affichage conditionnel selon l'acceptation des cookies */}
            {cookiesAccepted ? (
              <form className="space-y-4" action="#" method="post" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Votre nom
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Votre nom"
                    required
                  />
                  {nameError && <p className="text-sm text-red-600">{nameError}</p>}
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Votre email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                  />
                  {emailError && <p className="text-sm text-red-600">{emailError}</p>}
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                  {passwordError && <p className="text-sm text-red-600">{passwordError}</p>}
                </div>
                <div>
                  <label
                    htmlFor="cpassword"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirmer votre mot de passe
                  </label>
                  <input
                    type="password"
                    name="cpassword"
                    id="cpassword"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <Stack direction={"row"} spacing={2} justifyContent={"left"}>
                  <Checkbox label="" color="primary" checked={isChecked} onChange={handleCheckboxChange} />
                  <Typography paragraph="true" sx={{ padding: "16px 0", m: 0 }} color="secondary">
                    J'ai lu et j'accepte
                    <Link color="inherit" href="/conditions" target="_blank" rel="noopener noreferrer">
                      La politique de confidentialité et les conditions générales d'utilisation du site
                    </Link>
                  </Typography>
                </Stack>

                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  disabled={!isButtonEnabled}
                >
                  Créer un compte
                </button>
              </form>
            ) : (
              <div>
                <p>Veuillez accepter les cookies pour activer le formulaire d'inscription.</p>
                <Button onClick={acceptCookies} variant="contained">
                  Accepter les cookies
                </Button>
              </div>
            )}

            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              J'ai déjà un compte{" "}
              <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                Je me connecte
              </Link>
            </p>
          </div>
        </Box>
      </Stack>
    </Box>
  );
}
