import React from "react";
import { Box, Link, Typography, Stack, Paper, Divider } from "@mui/material";

const CadreJuridique = () => {
  const liens = [
    {
      name: "Dotation de voyage",
      date:"30 décembre 2015",
      description:
        "Solution pour la gestion des dotations des voyages des personnes physiques",
      liens: [
        {
          textlien: "Télécharger le circulaire n°3/2015 ",
          url: `${process.env.REACT_APP_BASE_FILE_URL}/ressources/cadrejuridique/circulaire n°3 2015.pdf`,
        },
      ],
    },
    {
      name: "Change manul ",
      date:"Juillet 2018",
      description: "Instruction régissant l’activité de change manul",
      liens: [
        {
          textlien: "Télécharger le pdf des instructions",
          url: `${process.env.REACT_APP_BASE_FILE_URL}/ressources/cadrejuridique/InstructionChangeManuel 2018.pdf`
        },
      ],
    },
    {
      name: "Obligations de vigilance",
      date:"02 décembre 2021",
      description:
        "Obligations de vigilance et de veille interne incombant aux sociétés de change de devises en application de la loi 43-05",
      liens: [
        {
          textlien: "Télécharger le Circulaire n° 1-2021 ",
          url: `${process.env.REACT_APP_BASE_FILE_URL}/ressources/cadrejuridique/Circulaire n° 1-2021.pdf`,
        },
      ],
    },
    {
      name: "IGOC 2024",
      date:"2 janvier 2024",
      description:
        "Instruction générale des opérations de change",
      liens: [
        {
          textlien: "Télécharger le pdf des IGOC 2024 ",
          url: `${process.env.REACT_APP_BASE_FILE_URL}/ressources/cadrejuridique/circulaire n°3 2015.pdf`,
        },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black", // Couleur de fond noire
        color: "white", // Texte en blanc
        padding: "20px 0",
        textAlign: "center",
        marginTop: "auto",
        //   borderRadius: { xs: '0', sm: '160px 0 0 0' }, // Arrondi du footer
        boxShadow: 3, // Ajouter une ombre pour plus de profondeur
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 4, color: "#ffdb02" }}>
      Cadre juridique de la réglementation du change manuel 
      </Typography>
      <Box
          sx={{

            pb: { xs: 2, sm: 4 },
            pl: { xs: 8, sm: 12 },
            pr: { xs: 8, sm: 12 },
           
          }}
      >
        <Typography variant="body1" align="left" >
      Le cadre juridique de la réglementation du change manuel définit les règles et procédures régissant les opérations de change effectuées par les établissements autorisés. Ce cadre est essentiel pour assurer la transparence, la sécurité, et le respect des normes internationales dans les transactions de change manuel. Il fixe les obligations des bureaux de change, les droits des clients, ainsi que les conditions de contrôle et de conformité imposées par les autorités.
            </Typography>
      </Box>
      
      <Stack
  direction={{ xs: "column", sm: "row" }} // Aligns vertically on small screens, horizontally on large screens
  justifyContent="center"
  alignItems={{ xs: "start", sm: "start" }} // Aligns items
  spacing={4} // Spacing between items
  sx={{ flexWrap: "wrap" }} // Allows items to wrap to the next line
>
  {liens.map((lien, index) => (
    <Stack
      key={index} // Add a unique key for each stack
      direction="column"
      justifyContent="center"
      alignItems="start"
      spacing={2}
      sx={{
        color: "#fffde7",
        backgroundColor: "black",
        maxWidth: 360,
        padding: 2, // Adds padding for better spacing
      }}
    >
      <Box>
        <Typography variant="h6">{lien.name}</Typography>
        <Divider sx={{ backgroundColor: "white" }} />
        <Typography variant="body2" align="left" sx={{ fontSize: '0.8rem' }}>{lien.date}</Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ textAlign: 'left' }}>{lien.description}</Typography>
      </Box>
      
      {lien.liens.map((subLien, subIndex) => (
        <Link
          key={subIndex}
          href={subLien.url} // Use the actual URL from the object
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#fffde7",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
              color: "yellow", // White on hover
            },
          }}
        >
          <Typography variant="body2">{subLien.textlien}</Typography>
        </Link>
      ))}
    </Stack>
  ))}
</Stack>

    </Box>
  );
};

export default CadreJuridique;
