import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from "../../../axios";
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError(null);

        try {
            const response = await axios.post('/reset-password', {
                token,
                email,
                password,
                password_confirmation: confirmPassword
            });
            setMessage(response.data.message);
        } catch (error) {
            setError(error.response?.data?.message || 'Une erreur est survenue. Veuillez réessayer.');
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pl: { xs: 4, sm: 8 }, pr: { xs: 4, sm: 8 } }}>
            <Stack direction={'column'} spacing={2}>
                <Helmet>
                    <title>Réinitialiser le mot de passe - EXCHANGE.COM</title>
                </Helmet>
                <Stack direction={'row'} spacing={2} justifyContent={'center'} sx={{ pt: 2, pb: 4 }}>
                    <img src="images/logo1.png" alt="logo" style={{ borderRadius: '50%', width: 80 }} />
                </Stack>
                <Box className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <Typography variant="h5" fontWeight="bold" textAlign="center" color="text.primary">
                            Réinitialiser le mot de passe
                        </Typography>
                        
                        {error && (
                            <Box className="p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50">
                                {error}
                            </Box>
                        )}
                        {message && (
                            <Box className="p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50">
                                {message}
                            </Box>
                        )}
                        
                        <form className="space-y-4" onSubmit={handleResetPassword}>
                            <TextField 
                                fullWidth
                                label="Nouveau mot de passe"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <TextField 
                                fullWidth
                                label="Confirmer le mot de passe"
                                variant="outlined"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <Button fullWidth type="submit" variant="contained" color="primary">
                                Réinitialiser
                            </Button>
                        </form>
                    </div>
                </Box>
            </Stack>
        </Box>
    );
}

export default ResetPassword;
