import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./style.css";
import Calculator from "../calculator/Calculator";
import CalculatorSide from "./CalculatorSide";
import TypeOperation from "../calculator/TypeOperation";
import CalculatorAchat from "../calculator/CalculatorAchat";
import CalculatorSideAchat from "./CalculatorSideAchat";

import { setSteep } from "../../app/services/reservationSlice";
import { useDispatch } from "react-redux";
// import { useEffect } from "react";

const SimulatorAchat = () => {
    const dispatch=useDispatch();
    const componentRef = useRef(null);
useEffect(() => {
    // Modifier seulement `steep` à 1 lors du chargement du composant
    dispatch(setSteep(0));
}, [dispatch]);
useEffect(() => {
  // Remettre le défilement en haut de la page à chaque chargement
  // window.scrollTo(0, 0);
  componentRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
}, []);


  return (
      <Stack 
      ref={componentRef} 
      sx={{ scrollMarginTop: "80px" }}
      direction={{xs:'column',sm:'row'}} 
      justifyContent={'space-around'}
      >
        <CalculatorAchat/>
        <CalculatorSideAchat/>
    </Stack>
  );
};

export default SimulatorAchat;
