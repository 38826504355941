import React, { createContext, useState, useEffect } from 'react';

// Créer un context pour l'acceptation des cookies
export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const userConsent = localStorage.getItem('userConsent');
    if (userConsent === 'true') {
      setCookiesAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('userConsent', 'true');
    setCookiesAccepted(true);
  };

  return (
    <CookieContext.Provider value={{ cookiesAccepted, acceptCookies }}>
      {children}
    </CookieContext.Provider>
  );
};
