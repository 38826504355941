import { Box, Button, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Bureau from "./Bureau";
import ChangeInfo from "./ChangeInfo";
import { addBureau } from "../../app/services/reservationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllBureausQuery } from "../../app/services/bureauApi";
import Loader from "../loader/Loader";
import dayjs from "dayjs";
import { format } from 'date-fns';
import { setSteep } from "../../app/services/reservationSlice";


function RetraitInfo() {
  const location = useLocation();
  const componentRef = useRef(null);

  const dispatch=useDispatch();
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    //window.scrollTo(0, 0);
    componentRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
}, []);
  useEffect(() => {
      // Modifier seulement `steep` à 1 lors du chargement du composant
      dispatch(setSteep(0));
  }, [dispatch]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  

  const typeOperation = useSelector((state) => state.change.value.typeOperation);
  const dateActuelle = dayjs();
  
  const dateActuelleFormatee = format(dateActuelle.toDate(), 'dd/MM/yyyy');
  const [dateRetrait, setDateRetrait] = useState(dayjs());
  const [heureRetrait, setHeureRetrait] = useState();
  const [bureauId, setBureauId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { data: bureaus, isLoading } = useGetAllBureausQuery();

  const members = bureaus?.map((bureau) => (
    <MenuItem key={bureau.id} value={bureau.id}>
      
      <Stack direction="column" spacing={0.5}>
        <Typography variant="subtitle1" fontWeight="bold">
          {bureau.nom}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {bureau.quartier}
        </Typography>
      </Stack>
    </MenuItem>
  ));

  if (isLoading) {
    return <Loader />;
  }

  const handleChangeBureau = (event) => {
    setBureauId(event.target.value);
    setErrorMessage("");
  };

  const handleChangeHeure = (event) => {
    setHeureRetrait(event.target.value);
  };

  const handleSuivantClick = () => {
    if (!bureauId || !dateRetrait) {
      setErrorMessage("Veuillez sélectionner un bureau et une date de retrait.");
      return;
    }

    dispatch(
      addBureau({
        bureauRetrait: bureauId,
        dateRetrait,
        heureRetrait,
        steep: 2,
      })
    );
    navigate("/reservation/reservationclient");
  };

  const handlePrecedentClick = () => {
    if (!bureauId || !dateRetrait) {
      setErrorMessage("Veuillez sélectionner un bureau et une date de retrait.");
      return;
    }

    dispatch(
      addBureau({
        bureauRetrait: bureauId,
        dateRetrait,
        heureRetrait,
        steep: 1,
      })
    );
    navigate(typeOperation === 'vente' ? "/reservation/simulatorachat" : "/reservation/simulatorvente");
  };

  // Fonction pour comparer les heures passées
  const estPasse = (heurePlage) => {
    const [debut, fin] = heurePlage.split(" - ");
    const maintenant = dayjs();
    return maintenant.isAfter(dayjs(`${dateRetrait.format('YYYY-MM-DD')} ${fin}`));
  };

  // Liste des plages horaires
  const heuresDisponibles = [
    "08:00 - 10:00",
    "10:00 - 12:00",
    "12:00 - 14:00",
    "14:00 - 16:00",
    "16:00 - 18:00",
    "18:00 - 20:00",
    "22:00 - 23:59",
  ];

  return (
    <Box ref={componentRef} sx={{ scrollMarginTop: "80px" }}>
    <Stack
      id="bureau"
      direction={{ xs: 'column', sm: 'row' }} 
      spacing={2} 
      justifyContent="space-between"
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 2,
          borderRadius: 2,
          border: "solid 1px goldenrod",
          flex: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "black",
            width: "100%",
            borderRadius: 2,
            padding: "16px",
            mb: 2,
          }}
        >
          <Typography variant="body1" color="goldenrod" textAlign="center">
            Sélectionner le bureau de change et la date de retrait de votre argent
          </Typography>
        </Box>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2}
        >
          <Box
            sx={{
              padding: 2,
              flex: 1,
            }}
          >
            <form>
              <InputLabel id="bureau-label">Bureau de retrait</InputLabel>
              <Select
                labelId="bureau-label"
                id="bureau-select"
                label="Bureau"
                onChange={handleChangeBureau}
                sx={{ minWidth: '100%' }}
              >
                {members}
              </Select>

              <InputLabel id="date-label" sx={{ mt: 2 }}>Date de retrait</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ maxWidth: '100%', mt: 1 }}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date de retrait"
                      value={dateRetrait}
                      onChange={(date) => setDateRetrait(dayjs(date))}
                      minDate={dateActuelle}
                      sx={{ width: '100%' }}
                    />
                  </DemoContainer>
                </Box>
              </LocalizationProvider>

              <InputLabel id="heure-label" sx={{ mt: 2 }}>Heure de retrait</InputLabel>
              <Select
                labelId="heure-label"
                id="heure-select"
                onChange={handleChangeHeure}
                sx={{ minWidth: '100%' }}
              >
                {dateRetrait.isSame(dateActuelle, 'day')
                  ? heuresDisponibles.filter((heure) => !estPasse(heure)).map((heure) => (
                      <MenuItem key={heure} value={heure}>
                        {heure}
                      </MenuItem>
                    ))
                  : heuresDisponibles.map((heure) => (
                      <MenuItem key={heure} value={heure}>
                        {heure}
                      </MenuItem>
                    ))}
              </Select>
            </form>

            {errorMessage && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}

            <Box sx={{ mt: 3 }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  paddingBottom: 2,
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "goldenrod",
                    color: "black",
                  }}
                  onClick={handlePrecedentClick}
                >
                  Précédent
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "goldenrod",
                    color: "black",
                  }}
                  onClick={handleSuivantClick}
                >
                  Suivant
                </Button>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>{bureauId && <Bureau id={bureauId} />}</Box>
        </Stack>
      </Box>

      <ChangeInfo />
    </Stack>
    </Box>
  );
}

export default RetraitInfo;
