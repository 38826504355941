import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import Calculator from "../calculator/Calculator";
import CalculatorSide from "./CalculatorSide";
import TypeOperation from "../calculator/TypeOperation";
import CalculatorVente from "../calculator/CalculatorVente";
import CalculatorSideVente from "./CalculatorSideVente";
import { setSteep } from "../../app/services/reservationSlice";
import { useDispatch } from "react-redux";
import { useEffect,useRef } from "react";

const SimulatorVente = () => {
  const dispatch=useDispatch();
  const componentRef = useRef(null);
  useEffect(() => {
      // Modifier seulement `steep` à 1 lors du chargement du composant
      dispatch(setSteep(0));
  }, [dispatch]);
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    //window.scrollTo(0, 0);
    componentRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
}, []);
  return (
    <Stack
      ref={componentRef}
      direction={{ xs: "column", sm: "row"}}
      sx={{ scrollMarginTop: "80px" }}
      justifyContent={"space-around"}
    >
      <CalculatorVente />
      <CalculatorSideVente />
    </Stack>
  );
};

export default SimulatorVente;
