import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useGetReservationQuery } from "../../app/services/reservationApi";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loader from "../loader/Loader"

import { setSteep } from "../../app/services/reservationSlice";
import { useDispatch } from "react-redux";


function ConfirmReservation() {
  const { id } = useParams();
  const location = useLocation();
  const { data: reservation, isLoading, isError } = useGetReservationQuery(id);

  const dispatch=useDispatch();
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  useEffect(() => {
      // Modifier seulement `steep` à 1 lors du chargement du composant
      dispatch(setSteep(4));
  }, [dispatch]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const generatePDF = () => {
    if (!reservation || !reservation.Reservation || !reservation.bureau) return;

    const { Reservation, bureau } = reservation;

    const rowData = [
      ["Code de réservation", id],
      ["Nom de famille", Reservation.nomFamille],
      ["Prénom", Reservation.prenom],
      [
        "Type d'opération",
        `${
          Reservation.typeOperation === "vente"
            ? "Vous achetez : " +
              reservation.Reservation.MontantToReceive +
              " " +
              reservation.Reservation.devise +
              " contre " +
              reservation.Reservation.montantToExchange +
              " MAD"
            : "Vous vendez : " +
              reservation.Reservation.montantToExchange +
              " " +
              reservation.Reservation.devise +
              " contre " +
              reservation.Reservation.MontantToReceive +
              " MAD"
        }`,
      ],
      ["Taux de change", `1 ${Reservation.devise} = ${Reservation.taux} MAD`],
      ["Bureau de retrait", bureau.nom],
      ["Adresse du bureau", bureau.adresse],
      ["Téléphone du bureau", bureau.telephone],
      ["Date de retrait", Reservation.dateRetrait],
      ["Heure de retrait", Reservation.heureRetrait],
    ];

    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text("EXCHANGE.COM", 20, 20);
    doc.setFontSize(16);
    doc.text("Votre réservation a été effectuée avec succès", 20, 30);
    doc.autoTable({
      startY: 40,
      head: [["Information", "Valeur"]],
      body: rowData,
    });
    doc.setFontSize(12);
    doc.text(
      "NB: veuillez présenter votre document d'identité au moment de la récupération de votre argent.",
      20,
      doc.autoTable.previous.finalY + 10
    );

    doc.save(`Reservation_${id}.pdf`);
  };

  if (isLoading) return <Loader />;
  if (isError) return <p>Error...</p>;

  return (
    <Box
      id={`confirmereservation/${id}`}
      sx={{
        padding: 4,
        backgroundColor: "black",
        margin: "auto",
        borderRadius: 2,
        color: "yellow",
      }}
    >
      <Typography variant="h6"></Typography>
      <Alert severity="success">
        Votre réservation a été effectuée avec succès. Merci d'avoir choisi nos services.
      </Alert>
      <Typography variant="h5">Code de votre réservation : {id} </Typography>
      <Typography variant="h6">Coordonnées de retrait</Typography>

      {reservation && reservation.Reservation && (
        <>
          <Typography variant="body1">
            <strong>Nom de famille :</strong> {reservation.Reservation.nomFamille}
          </Typography>
          <Typography variant="body1">
            <strong>Prénom :</strong> {reservation.Reservation.prenom}
          </Typography>
          <Typography variant="body1">
            <strong>Type d'opération :</strong> 
            {reservation.Reservation.typeOperation === "vente"
              ? "Vous achetez : " +
                reservation.Reservation.MontantToReceive +
                " " +
                reservation.Reservation.devise +
                " contre " +
                reservation.Reservation.montantToExchange +
                " MAD"
              : "Vous vendez : " +
                reservation.Reservation.montantToExchange +
                " " +
                reservation.Reservation.devise +
                " contre " +
                reservation.Reservation.MontantToReceive +
                " MAD"}
          </Typography>
          <Typography variant="body1">
            <strong>Taux de change :</strong> 1 {reservation.Reservation.devise} = {reservation.Reservation.taux} MAD
          </Typography>
          <Typography variant="body1">
            <strong>Bureau de retrait :</strong> {reservation.bureau.nom}
          </Typography>
          {reservation.bureau && (
            <>
              <Typography variant="body1">
                <strong>Adresse du bureau :</strong> {reservation.bureau.adresse}
              </Typography>
              <Typography variant="body1">
                <strong>Téléphone du bureau :</strong> {reservation.bureau.telephone}
              </Typography>
            </>
          )}
          <Typography variant="body1">
            <strong>Date de retrait :</strong> {reservation.Reservation.dateRetrait}
          </Typography>
          <Typography variant="body1">
            <strong>Heure de retrait :</strong> {reservation.Reservation.heureRetrait}
          </Typography>

          <Button variant="contained" onClick={generatePDF}>
            <DownloadForOfflineIcon />
            Télécharger votre ticket
          </Button>
        </>
      )}
    </Box>
  );
}

export default ConfirmReservation;
