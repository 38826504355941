import React, { useContext } from 'react';
import CookieConsent from 'react-cookie-consent';
import { CookieContext } from '../../contexts/CookieContext';

const CookieConsentComponent = () => {
  const { acceptCookies } = useContext(CookieContext);

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Je refuse"
        enableDeclineButton
        cookieName="userConsent"
        onAccept={() => acceptCookies()}
        onDecline={() => console.log("Cookies refusés")}
        style={{
          background: "white",
          color: "#000",
          minHeight: "150px",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left"
        }}
        buttonStyle={{
          backgroundColor: "#FFD700",
          color: "#000",
          fontSize: "14px",
          borderRadius: "5px",
          padding: "10px 20px"
        }}
        declineButtonStyle={{
          background: "#FFD700",
          color: "#000",
          fontSize: "14px",
          borderRadius: "5px",
          padding: "10px 20px"
        }}
        expires={365}
      >
        Ce site utilise des cookies pour vous offrir la meilleure expérience possible...
      </CookieConsent>
    </div>
  );
};

export default CookieConsentComponent;
