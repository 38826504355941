import { useState } from "react";
import axios from "../../../axios";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError(null);
        
        try {
            const { data } = await axios.post("/forgot-password", { email });
            setMessage(data.message);
        } catch (err) {
            setError("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pl: { xs: 4, sm: 8 }, pr: { xs: 4, sm: 8 } }}>
            <Stack direction={"column"} spacing={2}>
                <Helmet>
                    <title>Réinitialiser le mot de passe - EXCHANGE.COM</title>
                </Helmet>
                <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 2, pb: 4 }}>
                    <img src="images/logo1.png" alt="" style={{ borderRadius: "50%", width: 80 }} />
                </Stack>
                <Box className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <Typography variant="h5" fontWeight="bold" textAlign="center" color="text.primary">
                            Réinitialiser le mot de passe
                        </Typography>
                        {error && (
                            <Box className="p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50">
                                {error}
                            </Box>
                        )}
                        {message && (
                            <Box className="p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50">
                                {message}
                            </Box>
                        )}
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <TextField 
                                fullWidth
                                label="Votre email"
                                variant="outlined"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <Button fullWidth type="submit" variant="contained" color="primary">
                                Envoyer
                            </Button>
                        </form>
                        <Typography variant="body2" textAlign="center" color="text.secondary">
                            <Link to="/login" className="font-medium text-primary-600 hover:underline">
                                Retour à la connexion
                            </Link>
                        </Typography>
                    </div>
                </Box>
            </Stack>
        </Box>
    );
};

export default ForgotPassword;
